.accordion-container {
    display: flex;
    justify-content: center; /* Centra las columnas horizontalmente */
    gap: 8rem; /* Espacio entre las columnas */
    padding: 5rem 10rem; /* padding uniforme */
    box-sizing: border-box; /* Asegúrate de que el padding no exceda el ancho disponible */
  }
  
  .accordion-column {
    flex: 1; /* Cada columna toma una parte igual del espacio disponible */
    box-sizing: border-box; /* Incluye padding y bordes en el ancho total */
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .accordion-item {
    display: flex;
    flex-direction: column;
    flex-basis: 100%; /* Iniciamos con una sola columna para dispositivos móviles */
    padding: 1rem 0 0 0.2rem; /* Espaciado vertical para cada elemento */
    border-bottom: 1px solid; /* Borde en el título, el color se definirá en el estilo en línea o en una clase */
  }
  
  .accordion-title {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    margin: 0 1rem; /* Margen horizontal para mantener el contenido centrado */
  }
  
  .accordion-content {
    background-color: transparent;
    margin: 0 0 0 2rem; /* Margen horizontal para el contenido */
    padding: 1rem 0; /* Espaciado vertical para el contenido */
  }
  
  .accordion-icon {
    font-size: 1rem; /* Ajusta según necesites */
    color: #0542C7;
  }
  
  /* Responsividad para pantallas medianas hacia arriba */
  @media (max-width: 768px) {
    .accordion-container {
      flex-direction: column;
      align-items: center; /* Centra las columnas verticalmente en modo móvil */
      padding: 5rem 2rem; /* Reduce el padding en dispositivos más pequeños */
      gap: 1rem; /* Reduce el espacio entre elementos en dispositivos más pequeños */
    }
  
    .accordion-column {
      margin-top: 0;
      margin-bottom: 0;
      /* Asegúrate de que las columnas ocupen el ancho completo disponible en móviles */
      width: 100%;
    }

  }
  /* Estilos para cuando el acordeón esté abierto */
  .accordion-item.open .accordion-content {
    display: block; /* Muestra el contenido */
  }
  
.bg-accord {
    background: conic-gradient(from 0deg at 50% 50%, rgba(4, 2, 29, 0.2) 0deg, rgba(42, 84, 214, 0.2) 360deg);

}

.text-color {
    color: #FFF
}


