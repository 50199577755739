:root {
    --primary-color: #E21454;
    --secondary-color: #1F43B2;
    --text-color: #BFDCFE;
}

.bg-blog {
    background-color: whitesmoke;
}

.post-title-h1-primary,
.divider-solid {
    color: var(--primary-color);
}

.post-title-h2-primary {
    color: var(--secondary-color);
}

/* .post-p,
.post-p-small {
    color: var(--text-color);
} */

.post-title-h1-primary {
    font-weight: 700;
    font-size: 2.813rem;
}

.post-p {
    font-size: 1.563rem;
}

.post-p-small {
    color: var(--bs-body-color);
    text-decoration: none;
    font-size: 1rem;
}

.divider-solid {
    border-top: 2px solid;
}

@media(width <=767.98px) {

    #postNav,
    #postInfoHead {
        .post-p-small {
            font-size: 0.75rem;
        }
    }

    #post {
        .post-title-h1-primary {
            font-size: 1.6rem;
        }

        .post-p {
            font-size: 0.9rem;
        }
    }
}